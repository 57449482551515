<template>
  <el-container id="app">
    <el-header>
      <el-row type="flex" justify="center" align="middle">
        <el-col :span="24" class="avatar-container">
          <el-avatar :src="avatarUrl" class="large-avatar"></el-avatar>
        </el-col>
        <el-col :span="24">
          <el-header>
            <h1 class="user-name">godmao233</h1>
          </el-header>
        </el-col>
        <el-col :span="24">
          <el-input v-model="inputValue" placeholder="留言" class="input-box"></el-input>
        </el-col>
        <el-col :span="24" class="button-container">
          <el-button type="primary" @click="sendMessage" class="send-button">发送</el-button>
        </el-col>
      </el-row>
    </el-header>
  </el-container>
</template>

<script>
import axios from "axios";

export default {
  name: 'App',
  created() {
    this.axiosInstance = axios.create({
      baseURL: this.serverUrl,
      withCredentials: true,
    });
  },
  data() {
    return {
      axiosInstance: null,
      serverUrl: 'https://godmao.com',
      avatarUrl: 'https://io.godmao.com/hub/godmao.png',
      inputValue: '',  // 绑定输入框的值
    };
  },
  methods: {
    sendMessage() {
      this.axiosInstance.get("/send/mail", {
        params: {
          message: this.inputValue
        },
      }).then(response => {
        console.log(response);
        this.$message.info(response.data);
      });
    }
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

#app {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
}

.el-header {
  text-align: center;
}

.avatar-container {
  margin-bottom: 20px;
}

.large-avatar {
  width: 200px; /* 自定义头像大小 */
  height: 200px;
  font-size: 100px; /* 字体大小 */
}

.user-name {
  font-size: 2em;
  color: #333;
}

/* 椭圆形输入框样式 */
.input-box {
  margin-top: 20px;
  width: 50%; /* 根据需要调整宽度 */
  height: 50px; /* 高度设置为适中，避免太扁 */
  font-size: 1em;
}

/* 发送按钮样式 */
.button-container {
  margin-top: 20px;
  text-align: center;
}

.send-button {
  width: 20%; /* 根据需要调整按钮的宽度 */
  font-size: 1.2em;
}
</style>
